import React from "react"
import CampaignList from "../components/CampaignList"
import InstaCampaigns from "../components/InstaCampaigns"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SubHero from "../components/SubHero"

const MarketingCampaigns = () => {
  return (
    <Layout>
      <Seo
        title="Marketing Campaigns"
        description=" Over the years, I have developed a
            knack for making videos and an eye for marketing."
      />
      <SubHero title="Marketing Campaigns" type="campaigns" />
      <InstaCampaigns />
      <CampaignList />
    </Layout>
  )
}

export default MarketingCampaigns
