import React from "react"
import { MdOpenInNew } from "react-icons/md"
import { GiTrophyCup } from "react-icons/gi"
import AmazonPdf from "../../static/Nofiltr-X-Amazon-Prime-Day-Campaign-Report.pdf"

const data = [
  {
    title: "Amazon Prime Day 2019",
    intro: "3 days: 20 Influencers, 55 Posts, 418 Stories",
    caseStudyLink: AmazonPdf,
    videoLink: null,
    impressions: "26M+",
    awards: null,
  },
  {
    title: "Nykaa Man",
    intro: "1 Influencer: 3 videos",
    caseStudyLink: "https://youtu.be/zsG6zMf4R1Y",
    videoLink: "https://www.instagram.com/p/B2ZSWTgBI2R",
    impressions: "350k+",
    awards: null,
  },
  {
    title: "L'Oreal Paris",
    intro: "1 Influencer: 1 video - Recreating a Cannes Festival inspired look",
    caseStudyLink: null,
    videoLink: "https://www.instagram.com/p/ByiKWUhAEKd/?igshid=v74eati0ufnl",
    impressions: "45k+",
    awards: null,
  },
  {
    title: "CAI x Aashna Shroff",
    intro:
      "A capsule shoe-collection launch, 3 Teasers, 1 launch film, 15 shoes",
    caseStudyLink: null,
    videoLink: "https://www.instagram.com/p/CEBUqw1p5MV",
    impressions: "300k+",
    awards: null,
  },
  {
    title: "The Irish House BKC launch",
    intro:
      "A series of 3 videos made for The Irish House, for the launch of their new outlet at BKC, Mumbai",
    caseStudyLink: "",
    videoLink: "https://www.instagram.com/p/Bi_4_t8Hseq",
    impressions: null,
    awards: null,
  },
  {
    title: "#WomenInGreen - The Test Case - ALTBalaji",
    intro: null,
    caseStudyLink: "https://youtu.be/wtPPgX8j3wM",
    videoLink: null,
    impressions: null,
    awards: [
      "Indian Digital Marketing Awards 2018: Gender Parity",
      "Digixx 2019 - Best Social Media Campaign",
      "INKSPELL - Best Influencer Marketing Campaign - Masters Of Marketing 2018",
      "#SAMMIES 2018 - Best Influencer Marketing Campaign",
      "SAARC Awards For Excellence In Advertising - Viral Marketing",
    ],
  },
  {
    title: "Diplo x Charli XCX - Spicy (Music Track)",
    intro: "3 Influencers, 3 videos (TikTok + Instagram)",
    caseStudyLink: null,
    videoLink: null,
    impressions: null,
    awards: null,
  },
  {
    title: "Ananya Birla - Blackout (Music Track)",
    intro: null,
    caseStudyLink: null,
    videoLink: null,
    impressions: null,
    awards: null,
  },
  {
    title: "Sony Music - Tere Do Naina",
    intro: null,
    caseStudyLink: null,
    videoLink: null,
    impressions: null,
    awards: null,
  },
  {
    title: "B612",
    intro: null,
    caseStudyLink: null,
    videoLink: null,
    impressions: null,
    awards: null,
  },
]

const CampaignList = () => {
  return (
    <div className="campaignList">
      <div className="campaignList__intro">
        <h1>Campaigns</h1>
      </div>
      <div className="campaignList__list">
        {data.map(
          (
            { title, intro, videoLink, caseStudyLink, impressions, awards },
            i
          ) => (
            <div className="campaignList__list--item" key={i}>
              <h2>{title}</h2>
              {intro && <p>{intro}</p>}
              <div className="cta">
                {videoLink && (
                  <a href={videoLink} target="_blank" rel="noreferrer noopener">
                    <MdOpenInNew />
                    View Video
                  </a>
                )}
                {caseStudyLink && (
                  <a
                    href={caseStudyLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <MdOpenInNew />
                    View Case Study
                  </a>
                )}
              </div>
              {impressions && (
                <p className="impressions">Total Reach: {impressions}</p>
              )}
              {awards && (
                <>
                  <h3>Awards</h3>
                  <ul className="awards">
                    {awards.map((award, i) => (
                      <li key={i}>
                        <GiTrophyCup />
                        {award}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default CampaignList
