import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { A11y, Pagination, Autoplay } from "swiper"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { AiOutlineEye } from "react-icons/ai"

// Import Swiper styles
import "swiper/swiper.scss"

SwiperCore.use([A11y, Pagination, Autoplay])

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 2,
  grabCursor: true,
  speed: 750,
  allowTouchMove: true,
  updateOnWindowResize: true,
  autoplay: {
    delay: 1000,
  },
  pagination: { clickable: true },
}
const InstaCampaigns = () => {
  let data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "campaigns" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  let images = data.images.edges

  let sliderContent = [
    // ################# Irish Start
    {
      link:
        "https://www.instagram.com/p/Bi_4_t8Hseq/?taken-by=theirishhouseindia",
      views: null,
      image: images[0],
    },
    {
      link:
        "https://www.instagram.com/p/BjzX6CLH_oW/?taken-by=theirishhouseindia",
      views: null,
      image: images[1],
    },
    {
      link:
        "https://www.instagram.com/p/BjclaCrnFpq/?taken-by=theirishhouseindia",
      views: null,
      image: images[2],
    },
    {
      link: "https://www.instagram.com/p/BjMZ6KVHGN4/?taken-by=th",
      views: null,
      image: images[3],
    },
    // ################# Aashna Start
    {
      link: "https://www.instagram.com/p/CDTyRG3pw_f/?igshid=1m44giayl4oqk",
      views: "178k",
      image: images[4],
    },
    {
      link: "https://www.instagram.com/p/CDbV8B4J7tR/?igshid=10wcdnaixce49",
      views: "298k",
      image: images[5],
    },
    {
      link: "https://www.instagram.com/p/CDd3oxYJ0f8/?igshid=lprmml09n16s",
      views: "116k",
      image: images[6],
    },
    {
      link: "https://www.instagram.com/p/CDjF_8iJlnr/?igshid=1bt17kfsnrjrj",
      views: "102k",
      image: images[7],
    },
    {
      link: "https://www.instagram.com/p/CDydl43JsAx/?igshid=hkms8czdgd58",
      views: "69k",
      image: images[8],
    },
    {
      link: "https://www.instagram.com/p/CD_SR6iJX91/?igshid=1kmkeexf71r8n",
      views: "49k",
      image: images[9],
    },
    {
      link: "https://www.instagram.com/p/CEBUqw1p5MV/?igshid=4fuosgzhaao3",
      views: "82k",
      image: images[10],
    },
    {
      link: "https://www.instagram.com/p/CFCgcdNpPat/?igshid=1acztl8yygd9q",
      views: "103k",
      image: images[11],
    },
    // ################# Larissa Start
    {
      link: "https://www.instagram.com/tv/CEoxWcnnu_j/?igshid=cwq9n0wdszor",
      views: "160k",
      image: images[12],
    },
    {
      link: "https://www.instagram.com/tv/CEjIznLHY51/?igshid=12dffbbu8vj7p",
      views: "250k",
      image: images[13],
    },
    {
      link: "https://www.instagram.com/tv/CAmzRs4HEJq/?igshid=15dcqz3sqjoik",
      views: "272k",
      image: images[14],
    },
    // ################# Personal Start
    {
      link: "https://www.instagram.com/p/B1DeXcllN-V/?igshid=46pggyj70l9",
      views: "1.1k",
      image: images[15],
    },
    {
      link: "https://www.instagram.com/tv/B0LJjhWlTFV/?igshid=3u8qvwc6we3y",
      views: "823",
      image: images[16],
    },
  ]

  return (
    <div className="instaCampaigns">
      <div className="instaCampaigns__slider-wrap">
        <Swiper {...swiperConfig}>
          {sliderContent.map(({ link, image, views }, i) => (
            <SwiperSlide>
              <a href={link} target="_blank" rel="noreferrer noopener">
                <Img fluid={image.node.childImageSharp.fluid} key={i} />
                {views && (
                  <div className="insta-overlay">
                    <AiOutlineEye />
                    <p>{views}+</p>
                  </div>
                )}
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default InstaCampaigns
